<template>
    <section class="mobile-navigation">
        <div 
            @click="toggleMenu" 
            class="mobile-navigation__burger" 
            :class="{ active: isActive }">
            <span></span>
        </div>
        <nav class="mobile-navigation__items" :class="{ open: isOpen }">
            <ul>
                <li v-for="(link, index) in links" :key="index" @click="toggleMenu">
                    <router-link :to="link.route">{{ link.text }}</router-link>
                </li>
            </ul>
        </nav>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
            isOpen: false,
            links: [
                { route: '/', text: 'Home' },
                { route: '/about', text: 'About' },
                { route: '/playground', text: 'Playground' },
                { route: '/contact', text: 'Inquire' },
            ],
        };
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive;
            this.isOpen = !this.isOpen;
            document.body.classList.toggle('no-scroll', this.isOpen);
        }
    }
}
</script>

<style lang="scss" scoped>
.mobile-navigation {
    display: block;
    position: relative;
    &__burger {
        cursor: pointer;
        height: rem(25px);
        width: rem(35px);
        position: fixed;
        top: rem(35px);
        right: rem(20px);
        z-index: 1000;
        
        span {
            background-color: var(--color-black);
            display: block;
            position: absolute;
            height: rem(1px);
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            transition: background-color 0.3s;
            
            &::after, &::before {
                background-color: var(--color-black);
                content: '';
                display: block;
                height: rem(1px);
                width: 100%;
                position: absolute;
                transition: all 0.3s ease-in-out;
            }
            
            &::before {
                top: rem(-8px);
            }
            
            &::after {
                bottom: rem(-8px);
            }
        }
        
        &.active {
            span {
                background-color: transparent;
                
                &::before {
                    top: 0;
                    transform: rotate(45deg);
                    background-color: var(--color-white);
                }
                
                &::after {
                    bottom: 0;
                    transform: rotate(-45deg);
                    background-color: var(--color-white);
                }
            }
        }
    }
    
    &__items {
        background-color: var(--color-black);
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 999;
        visibility: hidden;
        
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            padding: rem(150px 32px 0 32px);
            margin: 0;
            
            li {
                margin-bottom: rem(20px);
                
                a {
                    font-family: var(--special-font);
                    font-size: rem(50px);
                    line-height: rem(60px);
                    color: var(--color-white);
                    text-decoration: none;
                    display: block;
                    
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
        
        &.open {
            transform: translateY(0);
            visibility: visible;
        }
    }
    
    @include desktop {
        display: none;
    }
}
</style>